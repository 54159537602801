import { auth } from "@/firebase";
import { Customer } from "shared/types/Customer";

const baseUrl = `https://${import.meta.env.VITE_FIREBASE_AUTH_DOMAIN}`;

/**
 * Get a customer by their email and location ID
 * @param {string} email - The email of the customer
 * @param {string} locationId - The ID of the location
 * @return {Promise<Customer | null>} - A promise that resolves to the customer or null if not found
 */
export const getCustomerByEmailAndLocation = async (
  email: string,
  locationId: string,
): Promise<Customer | null> => {
  const url = new URL(`${baseUrl}/getCustomer`);
  url.searchParams.append("email", email);
  url.searchParams.append("locationId", locationId);
  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
    },
  });
  if (response.status === 404) {
    return null;
  }
  if (!response.ok) {
    throw new Error(`Failed to fetch customer: ${response.statusText}`);
  }
  const data = await response.json();
  return data as Customer;
};

/**
 * Get a customer by their ID
 * @param {string} customerId - The ID of the customer
 * @return {Promise<Customer | null>} - A promise that resolves to the customer or null if not found
 */
export const getCustomerById = async (
  customerId: string,
): Promise<Customer | null> => {
  const url = new URL(`${baseUrl}/getCustomer`);
  url.searchParams.append("customerId", customerId);
  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
    },
  });
  if (response.status === 404) {
    return null;
  }
  if (!response.ok) {
    throw new Error(`Failed to fetch customer: ${response.statusText}`);
  }
  const data = await response.json();
  return data as Customer;
};

/**
 * Create a new customer
 * @param {Customer} customer - The customer to create
 * @return {Promise<Customer>} - A promise that resolves to the created customer object
 */
export const createCustomer = async (customer: Customer): Promise<Customer> => {
  const url = new URL(`${baseUrl}/createCustomer`);
  const response = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(customer),
  });
  if (!response.ok) {
    throw new Error(`Failed to create customer: ${response.statusText}`);
  }
  const data = await response.json();
  return data as Customer;
};
