import { auth } from "@/firebase";
import { getDocWithDates } from "@/utils/FirestoreHelpers";
import { doc, getFirestore } from "firebase/firestore/lite";
import { Product } from "shared/types/Product";
import { v4 as uuidv4 } from "uuid";
import { app } from "../firebase";

const db = getFirestore(app);
const baseUrl = `https://${import.meta.env.VITE_FIREBASE_AUTH_DOMAIN}`;

/**
 * Fetches products for a given location.
 * @param {string} locationId - The ID of the location.
 * @return {Promise<Product[]>} - A promise that resolves to an array of products.
 * @throws {Error} - If the fetch operation fails.
 */
export const getProducts = async (locationId: string) => {
  const url = new URL(`${baseUrl}/getProducts`);
  url.searchParams.append("locationId", locationId);
  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
    },
  });
  if (!response.ok) {
    throw new Error(`Failed to fetch products: ${response.statusText}`);
  }
  const data = await response.json();
  return data as Product[];
};

/**
 * Fetches a product by its ID from Firestore.
 * @param {string} productId - The ID of the product.
 * @return {Promise<Product>} - A promise that resolves to the product.
 * @throws {Error} - If the fetch operation fails.
 */
export const getProductById = async (productId: string): Promise<Product> => {
  try {
    const productRef = doc(db, "products", productId);
    const productDoc = await getDocWithDates(productRef);
    if (!productDoc || productDoc.deleted) {
      throw new Error(`No product found with ID: ${productId}`);
    }
    return productDoc as Product;
  } catch (error) {
    console.error(`Error fetching product: ${error}`);
    throw error;
  }
};

/**
 * Creates a new product for a given location.
 * @param {Product} product - The product to create.
 * @return {Promise<void>} - A promise that resolves when the product is created.
 * @throws {Error} - If the creation operation fails.
 */
export const createProduct = async (product: Product) => {
  product.id = uuidv4();
  const url = new URL(`${baseUrl}/createProduct`);
  const response = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      product: product,
    }),
  });
  if (!response.ok) {
    throw new Error(`Failed to create product: ${response.statusText}`);
  }
};

/**
 * Updates an existing product.
 * @param {Product} product - The product to update.
 * @return {Promise<void>} - A promise that resolves when the product is updated.
 * @throws {Error} - If the update operation fails.
 */
export const updateProduct = async (product: Product) => {
  const url = new URL(`${baseUrl}/updateProduct`);
  const response = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      product: product,
    }),
  });
  if (!response.ok) {
    throw new Error(`Failed to update product: ${response.statusText}`);
  }
};

/**
 * Deletes a product by its ID.
 * @param {string} productId - The ID of the product to delete.
 * @return {Promise<void>} - A promise that resolves when the product is deleted.
 * @throws {Error} - If the deletion operation fails.
 */
export const deleteProductById = async (productId: string) => {
  const url = new URL(`${baseUrl}/deleteProduct`);
  const response = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      productId: productId,
    }),
  });
  if (!response.ok) {
    throw new Error(`Failed to delete product: ${response.statusText}`);
  }
};
